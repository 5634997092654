import axios from 'axios';
const URL = "https://api.glas.com.br:213";
const api = axios.create({
    baseURL: URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type': 'application/json',
    },
    auth: {
        username: "infotec",
        password: "inf*251270"
    } 
    
});
export default api;