import React , { useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import api from "../../services/api"
import NotaFiscal from "../../components/notafiscal/lista";
import "./notafiscal.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable  , ptBR  } from "primereact/datatable" ;
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar"
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
import RequisicaoModal from "../../components/notafiscal/modal/index.jsx";
import ImpressaoPDF from "../../components/notafiscal/impressao/index.jsx";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { saveAs } from 'file-saver';


function NotasFiscais(){
    const [notasficais, setNotasFiscais] = useState([]);
    const [isRequisicaoOpen, setIsRequisicaoOpen] = useState(false);
    const [isImpressaoOpen, setIsImpressaoOpen] = useState(false);
    const [dadosNF, setDadosNF] = useState({requisicao: 0, pdf64: ""});
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [cpf, setCPF] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');    



    const actionBodyTemplate = (rowData) => {
        
        return (           

            <React.Fragment>
                <Button icon="pi pi-print" rounded outlined className="mr-15"  onClick={() => OpenRequisicao(rowData.operacao)} disabled={loading} />
            </React.Fragment>
        );}
      
        const formatDate = (value) => {
        return value.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
    const DataExameBodyTemplate = (rowData) => {
        const data_exame = new Date(rowData.datanf.substring(0, 19)); 
        return ( formatDate(data_exame) );
    };
    

    function ListarRequisicoes(){
        api.post('/v1/notafiscal', {
            cpf: localStorage.getItem('sessionId'),
        })
        .then(response => setNotasFiscais(response.data))
        .catch(err => console.log(err));
    }

 
    function OpenModalRequisicao(requisicao){

        if (requisicao > 0) {
            api.get(`/v1/notafiscal/${requisicao}`)
            .then(response => {
                setDadosNF(response.data);
                setLoading(true);
                setIsRequisicaoOpen(true); 
            })
            .catch(err => console.log(err));
        } else {
            setDadosNF([]);
            setIsRequisicaoOpen(true);
        }
        
    }

    function OpenRequisicao(requisicao) {
        if (requisicao > 0) {
            api.get(`/v1/notafiscalpdf/${requisicao}`, { responseType: 'arraybuffer'} )
            .then(response => {
                // Verifica se a resposta contém dados
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(blob);
                    window.open(pdfUrl, '_blank'); // Abre o PDF em uma nova aba
                   })

            .catch(err => console.log(err));
        } else {
            console.log("Requisição inválida.");
        }
    }


   
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
    };


   

    function closeModalRequisicao(){
        setIsRequisicaoOpen(false);
        setLoading(false);
        ListarRequisicoes();
    }

    useEffect(() => ListarRequisicoes(), []);


    return <div className="container-fluid mt-page">

        <Navbar />     

        <RequisicaoModal isOpen={isRequisicaoOpen} 
                       dadosNF={dadosNF}        
                       onRequestClose={closeModalRequisicao}
                        />
       
        <div className="container-fluid mt-page form-requisicao">
        <div className="table-wrapper">
            <DataTable  locale="pt_BR" value={notasficais} responsiveLayout="scroll" size="small" removableSort paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ Width: '100%' }   }>
            <Column field="nota_fiscal" header="Nota"  style={{ width: '5%' }} ></Column>
            <Column field="datanf" Column header="Data Competência" filterField="datanf" dataType="date"  style={{ width: '10%' }}  body={DataExameBodyTemplate} filter filterElement={dateFilterTemplate}   />	
            <Column field="valor_servicos" header="Valor NF " sortable style={{ width: '1%' }} >  </Column>
            <Column async  body={actionBodyTemplate} exportable={false} style={{ width: '10%' }}> </Column>
            </DataTable>      
            </div>
            </div>  
        </div>                    
}

export default NotasFiscais;