import { Navigate , BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./pages/login/index.jsx";
import Perfil from "./pages/perfil/index.jsx";
import PrivateRoute from "./components/private-route";
import NotasFiscais from './pages/notafiscal/notafiscal.jsx';

function Rotas(){
    return <>
        
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/" element={<Login />} />
                <Route exact path="/notafiscal" element={<NotasFiscais />} />
                <Route path="/perfil" element={<PrivateRoute><Perfil/></PrivateRoute>} />
            </Routes>
        </BrowserRouter>
    </>
}

export default Rotas;