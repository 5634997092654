import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoGlas from '../../assets/logo-glasp.png';
import LogoMansur from '../../assets/logo-mansurp.png';
import Fundo from '../../assets/fundo-login.jpg';
import { Link } from "react-router-dom";
import './style.css';
import api from '../../services/api';

function Login(){

    const navigate = useNavigate();
    const [cpf, setCPF] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState([]);
    const [dadosNF, setDadosNF] = useState(0);
    const [tipo, setTipo] = useState(0);
    const [sucesso, setSucesso] = useState('');
    const [loading, setLoading] = useState(false);

    function ProcessaLogin(e){
        e.preventDefault();

        console.log(cpf);

        setSucesso('');
        setLoading(true);
        
        api.post('v1/usuarios/login' , {
            cpf: cpf,
        })
        .then(response => {   
            setSucesso('S');
            setDadosNF(response.data);
            localStorage.setItem('sessionToken', response.data.token);
            localStorage.setItem('sessionId', response.data.cpf);
            localStorage.setItem('sessionEmail', response.data.email);
            localStorage.setItem('sessionNomePaciente', response.data.nome);
            localStorage.setItem('sessionTipo', 0);
            setLoading(false);
            console.log('passou ');
            navigate('/notafiscal')
        })
        .catch(err => {
            setSucesso('N');
            setLoading(false);
        })

    }

    return <div className="row">
        <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
            <form className="form-login mt-5">

                <h3 className="mb-4">Área do Paciente</h3>
                <h6 className="mb-3">Informe o CPF para Consulta de Nota Fiscal : </h6>

                <div className="form-floating">
                   <input type="email" onChange={(e) => setCPF(e.target.value)} className="form-control" id="floatingInput" placeholder="CPF" />
                    <label htmlFor="floatingInput">CPF</label>
                </div>

                <button onClick={ProcessaLogin} className="w-100 btn btn-lg btn-danger" disabled={loading}>
                    {loading ? <div>
                                <span className="spinner-border spinner-border-sm text-light" role="status"></span>
                                <span className="ms-2">Enviando...</span>
                                </div> : <span className="ms-2">Acessar</span>
                    }
                </button>

                {sucesso === 'N' ? <div className="alert alert-danger mt-2" role="alert"> Paciente não encontrado ! </div> : null}

                <img src={LogoGlas} alt="Delivery Mais" className="mt-5"/>
                <img src={LogoMansur} alt="Delivery Mais" className="mt-5"/>
            </form>    
        </div>

        <div className="col-sm-6 px-0 d-none d-sm-block">
            <img className="background-login" src={Fundo} alt="Delivery Mais" />
        </div>
        
    </div>
}

export default Login;