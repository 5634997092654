import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import closeIcone from '../../../assets/close.png';
import { Viewer } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import { Document, Page   } from 'react-pdf/dist/esm/entry.webpack5';
import { Button, Spinner } from '@react-pdf-viewer/core';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import './style.css';
//import * as pdfjs from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist/webpack';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

Modal.setAppElement('#root');

function RequisicaoModal(props) {
  const [operacao, setOperacao] = useState(0);
  const [pdf64, setPdf64] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    if (props.dadosNF) {
      setOperacao(props.dadosNF.operacao);
      setPdf64(props.dadosNF.pdf64);
    }
  }, [props.isOpen]);

  useEffect(() => {
    const base64toBlob = (data) => {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: 'application/pdf' });
    };
    

    const blob = base64toBlob(pdf64);
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);
  }, [props.dadosNF]);

const pageNavigationPluginInstance = pageNavigationPlugin();

const { CurrentPageInput, GoToFirstPageButton, GoToLastPageButton, GoToNextPageButton, GoToPreviousPage } =
    pageNavigationPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { DownloadButton } = getFilePluginInstance;
  const printPluginInstance = printPlugin();


  return (
    <Modal
      isOpen={props.isOpen}
      backdrop="static"
      onRequestClose={props.onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content">
        
      <button type="button" onClick={props.onRequestClose} className="react-modal-close">
        <img src={closeIcone} alt="Fechar" />
      </button>

      <div className="rpv-core__viewer">
        <div className="print-button-container">
          <printPluginInstance.PrintButton /> 
      </div>

        <div className="viewer-container">

          <Viewer fileUrl={pdfUrl} plugins={[printPluginInstance]} />
         
        </div>
    </div>
 

    </Modal>
  );
}

export default RequisicaoModal;
